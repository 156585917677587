<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="https://gbwhatsapks.net/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
            </li>
            <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
            </li>
            <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
            </li>
            <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <section id="section-1" class="section-content">
              <Breadcrumb :breadcrumbs="breadcrumbs" />

              <h1 class="section-1-blog-head">
                How to Disable Double Tick in GB WhatsApp?
              </h1>

              <div class="lazy-picture-container writer-banner full-width">
                <picture><img width="auto" height="auto" alt="GBWhatsApp update latest version"
                    src="../assets/how-to-disable-double-tick-in-gb-whatsapps.webp"></picture>
              </div>


              <h2 class="intro-title blog">
                How to Disable Double Tick in GB WhatsApp
              </h2>
              <p class="writter-content">
                <a href="https://gbwhatsapks.net/" class="jump-url">GB WhatsApp</a> , as a customized variant of
                WhatsApp, includes exclusive privacy features that the official
                version lacks.One of these features is the ability to <strong>disable the double tick</strong>, which
                appears when a
                message is delivered to the recipient. By disabling the double tick, the sender will only see a single
                tick, making it seem like the message was not delivered, even though you have received it. This feature
                is useful if you want to increase your privacy and avoid immediate responses. <br>
                In this guide, we will walk you through the steps to disable the double tick in GB WhatsApp.
              </p>


              <h2 class="intro-title blog">
                Why Disable the Double Tick in GB WhatsApp?
              </h2>
              <p class="writter-content">
                Disabling the double tick can be helpful for several reasons:
              </p>

              <ul>
                <li>
                  <strong>Increased Privacy</strong> – Prevent others from knowing when you have received their
                  messages.
                </li>
                <li>
                  <strong>Avoid Pressure to Reply</strong> – You can take your time to respond without feeling rushed.
                </li>
                <li>
                  <strong>Appear Offline </strong> Others may think you are offline even when you are online.
                </li>
                <li>
                  <strong>Reduce Unwanted Conversations</strong> – If people believe their messages are not being
                  delivered, they may not send repeated texts.
                </li>
              </ul>

              <h2 class="intro-title blog">
                How to Disable the Double Tick in GB WhatsApp
              </h2>
              <h3>Step 1: Open GB WhatsApp</h3>
              <p>
                Ensure you have GB WhatsApp installed on your device. Open the app and go to the home screen.
              </p>
              <h3>Step 2: Access GB WhatsApp Settings</h3>
              <ul>
                <li>Tap on the <strong>three-dot menu</strong> in the top-right corner.</li>
                <li>Select <strong>GB Settings</strong> or <strong>Privacy & Security</strong> (depending on your
                  version).</li>
              </ul>
              <h3>Step 3: Navigate to Privacy Settings</h3>
              <ul>
                <li>
                  Inside <strong>GB Settings</strong>, look for <strong>Privacy & Security</strong> and tap on it.
                </li>
                <li>
                  Under Privacy, you will find various options related to hiding online status, blue ticks, and double
                  ticks.
                </li>
              </ul>
              <h3>
                Step 4: Disable the Double Tick
              </h3>
              <ul>
                <li>
                  Find the "<strong>Contacts</strong>" or "<strong>Groups</strong>" section, depending on whether you
                  want to disable double ticks for personal chats or group chats.
                </li>
                <li>
                  Look for the "<strong>Hide Second Tick</strong>" option.
                </li>
                <li>
                  Toggle the switch or select <strong>Enable</strong> to turn it on.
                </li>
              </ul>
              <h3>
                Step 5: Save and Apply Changes
              </h3>
              <ul>
                <li>
                  Once you have enabled the setting, go back to the home screen.
                </li>
                <li>
                  Restart GB WhatsApp to ensure the changes take effect.
                </li>
              </ul>
              <p>
                Now, when someone sends you a message, they will only see a <strong>single tick</strong>, even though
                the message has been delivered to your device.
              </p>
              <h2 class="intro-title blog">
                Additional Privacy Features in GB WhatsApp
              </h2>
              <p class="writter-content">
                Apart from disabling the double tick, GB WhatsApp offers several other privacy features: <br>
                <strong>Hide Blue Ticks</strong> – Prevent others from knowing that you have read their message. <br>
                <strong>Hide Online Status</strong> – Appear offline even when you are using WhatsApp. <br>
                <strong>Freeze Last Seen</strong> – Display an old last seen time to avoid showing your real activity.
                <br>
                <strong>Hide Typing and Recording Status</strong> – Others won’t see when you are typing or recording a
                voice message. <br>
                These <a href="https://gbwhatsapks.net/how-to-download-gbwhatsapp-by-steps/" class="jump-url">key
                  features of GB WhatsApp</a> enhance user privacy and provide greater control over messaging
                interactions.
              </p>
              <h2 class="intro-title blog">
                Things to Keep in Mind
              </h2>
              <ul>
                <li><strong>Your Messages Are Still Delivered</strong> – Disabling double ticks does not stop message
                  delivery; it only
                  hides the delivery status from the sender.</li>
                <li> <strong>Some Users May Get Suspicious</strong> – If someone frequently messages you and only sees a
                  single tick, they
                  might realize that you are using a privacy feature.</li>
                <li> <strong>Feature Availability May Vary</strong> – Some older versions of GB WhatsApp may not have
                  this option, so make
                  sure you update to the latest version.</li>
              </ul>
              <h2 class="intro-title blog">
                Conclusion
              </h2>
              <p class="writter-content">
                Disabling the double tick in GB WhatsApp is a great way to maintain privacy and control over your
                conversations. By following the steps above, you can easily activate this feature and prevent others
                from knowing when their messages have been delivered. <br>
                If you want even more privacy, you can explore additional GB WhatsApp settings such as <strong>hiding
                  blue ticks, freezing last seen, or disabling online status.</strong> <br>
                Enjoy your private and stress-free chatting experience with GB WhatsApp!
              </p>
              <PostPagination :currentPage="7" />
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
            |
            <a href="https://gbwhatsapks.net/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>
    <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      href="https://gbwhatsapks.net/download-gbwhatsapp/">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import PostPagination from '@/components/PostPagination.vue';
import Breadcrumb from '@/components/BreadCrumb.vue';

export default {
  name: 'Home',
  components: {
    PostPagination,
    Breadcrumb,
  },
  data() {
    return {
      pageName: 'home',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  methods: {
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
